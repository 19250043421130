import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["wdayInput", "input"];
    
    defineWday(event) {
        this.wdayInputTarget.value = this.#parseDate(this.inputTarget.value).getDay();
    }

    #parseDate(dateString) {
        // Split the string into an array [DD, MM, YYYY]
        const parts = dateString.split('/');
      
        // Use the parts to create a Date object
        // Note: Subtract 1 from month because months are zero-based in JavaScript
        const dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
      
        return dateObject;
    }
}