import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const menuMobile = document.getElementById('hamburger-icon')
    const listMenu = document.getElementById('list-menu-mobile')
    function toggleMenuMobileDropdown() {
      listMenu.classList.toggle('display-menu-none')
    }
    menuMobile.addEventListener("click", toggleMenuMobileDropdown);
  }
}
