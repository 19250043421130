// app/javascript/controllers/flatpickr_controller.js
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

export default class extends Controller {

  static targets = [ "gameDate", "gameTime" ]

  connect() {
    let defaultDate = null;
    if (document.querySelector("#user-date-input")) {
      defaultDate = new Date(document.querySelector("#user-date-input").dataset.userDate);
    }
    if (document.querySelector('.string.required.form-control.input')) {
      document.querySelector('.string.required.form-control.input').remove();
    }
    if (this.gameDateTarget) {
      flatpickr(this.gameDateTarget, {
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "d/m/Y",
        prompt: "Sélectionnez une date",
        minDate: "today",
        "locale": French,
        theme: 'airbnb',
        defaultDate: defaultDate,
        disableMobile: "true"
      });
    }
    if (this.hasGameTimeTarget && document.querySelector(".game-time-input")) {
      flatpickr(this.gameTimeTarget, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        minuteIncrement: 30,
        time_24hr: true,
        disable: [
          function(date) {
            return (date.getMinutes() % 30 !== 0);
          }
        ]
      })
    }
  }
}
