const animatePlayerLevelForm = () => {

  // On desktop 
  document.querySelectorAll(".level-btn").forEach((level_button) => {
    level_button.addEventListener("click", (event) => {
      updateCards(level_button.dataset.value);
    })
  })

  // On mobile
  const mobile_player_level_select = document.querySelector("#mobile-player-level-select");
  mobile_player_level_select.onchange = function() {
    updateCards(this.value);
  }

  const updateCards = (selected_value) => {
    document.querySelectorAll(".player-level-card").forEach((player_level_card) => {
      player_level_card.classList.add("d-none");
    })

    document.querySelectorAll(".level-btn").forEach((level_btn) => {
      level_btn.classList.remove("bg-padelblue50")
    })

    const selected_player_level_card = document.querySelector(`#level-card-${selected_value}`);
    selected_player_level_card.classList.remove("d-none")

    const selected_level_btn = document.querySelector(`#level-button-${selected_value}`);
    selected_level_btn.classList.add("bg-padelblue50")
  }
};

export {animatePlayerLevelForm }