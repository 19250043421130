import Swal from 'sweetalert2'

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    redirectUrl: String,
    message: String
  }
  connect() {
    this.token = document.querySelector("meta[name='csrf-token']").content
  }

  async validate(e) {
    const response = await Swal.fire({
      text: this.messageValue,
      confirmButtonText: 'Confirmer',
      showDenyButton: true,
      denyButtonText: 'Annuler'
    })

    if (response.isConfirmed) {
      fetch(this.urlValue, {method: 'delete', 'headers': {'X-CSRF-Token': this.token}}).then(data => window.location.href = this.redirectUrlValue)
    }
  }
}
