import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets=["input", 'list']

  async search(e) {
    this.listTarget.innerHTML = ''
    if (this.inputTarget.value != '') {
      const response = await fetch(`/search_club?name=${this.inputTarget.value}`, {method: 'GET', headers: {
          "Accept": "text/plain",
        }})
      const data = await response.text()
      if (this.listTarget.children.length == 0) {
        this.listTarget.insertAdjacentHTML('beforeend', data)
      } else {
        this.listTarget.innerHTML = ""
        this.listTarget.insertAdjacentHTML('beforeend', data)
      }
    }
  }

  select(e) {
    this.listTarget.innerHTML = ''
    this.inputTarget.value = e.params['id']
  }
}
