import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'eye']

  connect() {
    console.log(this.inputTarget)
    console.log(this.eyeTarget)
  }

  toggle(_e) {
    if (this.inputTarget.type === "password") {
      this.eyeTarget.innerHTML = "<i class='far fa-eye'></i>"
      this.inputTarget.type = 'text'
    } else {
      this.eyeTarget.innerHTML = "<i class='far fa-eye-slash'></i>"
      this.inputTarget.type = 'password'
    }
  }
}
