import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "search", "list"]

  // update() {
  //   const url = `${this.formTarget.action}?search=${this.searchInputTarget.value}`
  //   fetch(url, { headers: { 'Accept': 'text/plain' } })
  //     .then(response => response.text())
  //     .then((data) => {
  //       this.listTarget.innerHTML = data;
  //     })
  // }
  toggleSearch(){
    this.searchTarget.classList.toggle("d-none")
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}
