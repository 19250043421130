import { Controller } from "stimulus";

export default class extends Controller {
    static values = { id: Number }

    connect() {
        this.token = document.querySelector("meta[name='csrf-token']").content
    }

    destroy(e) {
        e.stopPropagation()
        fetch(`/alerts/${this.idValue}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': this.token
            }
        }).then(response => response.ok ? this.element.remove() : alert('Erreur lors de la suppression'))     
    }
}