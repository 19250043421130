// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "controllers";
// import { initSelect2 } from '../components/init_select2'
import {animatePlayerLevelForm} from '../components/animate_player_level_form';
// import { previewImageOnFileSelect} from '../components/photo_preview'
import 'lightgallery/css/lightgallery.css';
import "bootstrap";
import { animateCarouselPopupModal } from '../components/animate_carousel_popup_modal';
import { createArticlesTableOfContent } from "../components/create_articles_table_of_content";
import { autofill } from '@mapbox/search-js-web';
// import { glide } from '../components/glide';

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import "@hotwired/turbo-rails"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// "Please wait" Loading Script :

// document.addEventListener('turbolinks:load', function() {
//   document.querySelector("#loading-spinner").classList.remove("show");

// })


// Other Scripts :

document.addEventListener('DOMContentLoaded', function() {
  document.querySelector('#loading-spinner').classList.add('show');
});

document.addEventListener('turbolinks:load', function() {

  document.querySelector("#loading-spinner").classList.remove("show");

  // initSelect2();
  // previewImageOnFileSelect();


  if (document.querySelector('#player-level-form')){
    animatePlayerLevelForm()
  }

  if (document.querySelector('#club-carousel-popup-modal')) {
		animateCarouselPopupModal();
	}

  if (document.querySelector('#club-form')){
    autofill({
      accessToken: document.querySelector('#club-form').dataset.apiKeyValue
      })
  }

  if (document.querySelector("#article-show")) {
    createArticlesTableOfContent();
  }

  // if (document.querySelector('#neighbour-clubs-slider')) {
	// 	glide();
	// }
});

require("trix")
require("@rails/actiontext")
