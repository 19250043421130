const animateCarouselPopupModal = () => {
	if (document.readyState === 'complete' || document.readyState === 'interactive') {

		// Open the popup
    if (document.querySelector("#btn-club-images-see-all")) {
      document.querySelector("#btn-club-images-see-all").addEventListener("click", (event) => {
        document.querySelector("#club-images-fullscreen-carousel").classList.remove("d-none")
      })
      // Close the popup
      document.querySelector("#club-carousel-popup-modal-close-btn").addEventListener("click", (event) => {
        document.querySelector("#club-images-fullscreen-carousel").classList.add("d-none")
      });
    }
  }
};

export {animateCarouselPopupModal }
