import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "suggestionsPanel"];
  static values = { apiKey: String }

  search(event) {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${this.apiKeyValue}&country=FR&types=place&autocomplete=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then((data) => {
        this.#clearSuggestions();
        this.#showSuggestions(data['features'].map(feature => feature['place_name']));
      });
  }

  #clearSuggestions() {
    this.suggestionsPanelTarget.innerHTML = "";
  }

  #showSuggestions(items) {
    // Logique pour afficher les suggestions sous le champ de recherche
    // Créez un élément HTML pour les suggestions et ajoutez-les à votre page
    let html = "";
    items.forEach(item => {
      html += this.#renderSuggestion(item);
    });
    this.suggestionsPanelTarget.innerHTML = html;
    document.querySelectorAll('.suggestions-panel li').forEach(el => {
      el.addEventListener('click', (event) => {
        this.inputTarget.value = event.target.innerText;
        this.#clearSuggestions();
      })
    })
  }

  #renderSuggestion(item) {
    return `
      <li>
          <div class="d-flex justify-content-between p-3">
          <div class="m-0-mobile">
              <div class="m-0-mobile"><strong class="font-mobile">${item}</strong></div>
          </div>
          <i class="fa-solid fa-chevron-right pe-2 fs-6"></i>
          </div>
      </li>`
  }
}
