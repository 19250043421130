import { Controller } from "@hotwired/stimulus"
import StarRating from "star-rating.js"

export default class extends Controller {
  connect() {
    new StarRating(this.element, {
      tooltip: false, 
      clearable: true, 
      defaultValue: 0
    })
  }
}