import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['preview', 'photo', 'empty']

  change(e) {
    if (this.hasEmptyTarget) {
      this.previewTarget.classList.remove('d-none')
      this.emptyTarget.classList.add('d-none')
    }
    const binaryData = [];
    const file = this.photoTarget.files[0]
    binaryData.push(file);
    const blob = new Blob(binaryData, {type: file.type})
    this.previewTarget.src = window.URL.createObjectURL(blob)
  }
}
