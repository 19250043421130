import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['message', 'suppress']

  connect() {
  }

  display(e) {
    this.messageTarget.classList.toggle('d-none')
    this.suppressTarget.classList.toggle('d-none')
  }
}
