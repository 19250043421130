import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'button']


  validate(e) {
    if (this.inputTarget.value === "Entrer une ville" || this.inputTarget.value === "" ) {
      e.preventDefault();
    }
  }

  clear(e) {
    if (this.inputTarget.value === "Entrer une ville") {
      this.inputTarget.value = ""
      this.inputTarget.placeholder = ""
    }
  }
}
