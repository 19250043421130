import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["display"];

  displayValue(e) {
    const value = event.target.value;
    this.displayTarget.textContent = `${value} km`;
  }
}
