// app/javascript/controllers/address_autocomplete_controller.js
import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

// Connects to data-controller="address-autocomplete"
export default class extends Controller {
  static values = { apiKey: String, controller: String }

  static targets = ["city", "screen", "mobile", "mobileInput"];

  connect() {

    let cityPrompt = "Entrer une ville"
    if (document.querySelector("#city-prompt")) {
      cityPrompt = document.querySelector("#city-prompt").dataset.cityPrompt;
    }
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      types: "place",
      countries: 'fr',
      placeholder: cityPrompt,
      render: this.#renderOption
    })
    if (window.innerWidth >= 992 && document.querySelector('.modal-city-input') ) {
      this.geocoder.addTo(this.screenTarget)
    } else {
      this.geocoder.addTo(this.mobileTarget)
    }

    this.geocoder.container.addEventListener("click",(el) => {
      this.geocoder.container.querySelector('input').value = ""
    })

    this.initializeUserCityInput();

    this.geocoder.on("result", event => this.#setInputValue(event))
    this.geocoder.on("clear", () => this.#clearInputValue()) ;

    if (window.innerWidth >= 992 && document.querySelector('.modal-city-input') ) {
      document.querySelector('.modal-city-input').disabled = true;
    }

    if(document.querySelector("#modal-city-value") && this.controllerValue != "users/registrations"){
      document.querySelectorAll("#modal-city-value").forEach((city_value) => {
        city_value.innerText = cityPrompt
        // document.querySelector(".mapboxgl-ctrl-geocoder--input").style.fontWeight = 'bold';

      })
    }

  }

  initializeUserCityInput() {
    const userCityInput = document.querySelector("#user-city-input");
    if (userCityInput) {
      const userCity = userCityInput.dataset.userCity;
      const capitalizedUserCity = userCity.charAt(0).toUpperCase() + userCity.slice(1);
      document.querySelector(".mapboxgl-ctrl-geocoder--input").value = capitalizedUserCity;
      if (document.querySelector("#user_city")){
        document.querySelector("#user_city").value = capitalizedUserCity;
      } else if (document.querySelector(".city")) {
        document.querySelector(".city").value = capitalizedUserCity;
      } else if (document.querySelector("#city_modal_input")) {
        document.querySelector("#city_modal_input").value = capitalizedUserCity;
      }
    }
  }

  closeCitySearchModal() {

  }

  #setInputValue(event) {
    const city = event.result.place_name.split(',')[0];
    // const country = place[place.length - 1];
    // const result = `${city.trim()}, ${country.trim()}`
    document.querySelector(".mapboxgl-ctrl-geocoder--input").value = city;
    if  (document.querySelector("#user_city")){
      document.querySelector("#user_city").value = city
    } else if (document.querySelector(".city")){
      document.querySelector(".city").value = city
    } else if (document.querySelector("#city_modal_input")) {
      document.querySelector("#city_modal_input").value = city;
    }

    document.querySelector("#modal-city-value").innerText = city

    const modalElement = document.querySelector("#citySearchModal");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-hidden", "true");
    modalElement.style.display = "none";
    document.body.classList.remove("modal-open");
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }

    if (this.controllerValue === "users/registrations") {
      this.mobileInputTarget.innerHTML = city
    }
  }

  #clearInputValue() {
    this.cityTarget.value = "";
  }

  disconnect() {
    this.geocoder.onRemove()
  }

  #renderOption(item) {
    const place = item.place_name.split(',');
    const city = place[0];
    const country = place[place.length - 1];
    return `
      <li>
        <div class="d-flex justify-content-between">
          <div class="m-0-mobile">
            <div class="m-0-mobile"><strong class="font-mobile"">${city.trim()}</strong></div>
            <div class="mb-3">${country.trim()}</div>
          </div>
          <i class="fa-solid fa-chevron-right pe-2 fs-6"></i>
        </div>
      </li>`
  }
}
