import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = [ 'form', 'list', 'searchInput', 'selectedInput', 'receiverId', 'validateButton' ];

	connect() {
    this.validateButtonTarget.classList.add('d-none');
  }

	update(data = null) {
		const url = `${this.formTarget.action}?query=${this.searchInputTarget.value}`;
		if (data === 'user selected') {
			this.listTarget.outerHTML = "<ul data-refresh-buddies-list-target='list'></ul>";
		} else {
			this.fetchData(url);
		}
	}

	fetchData(url) {
		fetch(url, { headers: { Accept: 'text/plain' } }).then((response) => response.text()).then((data) => {
			this.listTarget.outerHTML = data;
		});
	}

	select(event) {
    event.preventDefault();
    this.validateButtonTarget.classList.remove('d-none');
		this.searchInputTarget.innerHTML = '';
		this.searchInputTarget.value = event.params['name'];
		this.receiverIdTarget.value = event.params['id'];
		this.update('user selected');
	}
}
