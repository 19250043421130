const createArticlesTableOfContent = () => {
  replaceH1TagsByH2tags();
  createLinks();
}

const replaceH1TagsByH2tags = () => {
  document.querySelectorAll('.trix-content h1').forEach( element => {
    const newElement = document.createElement('h2');
    newElement.innerHTML = element.innerHTML;
    Array.from(element.attributes).forEach( attr => {
      newElement.setAttribute(attr.nodeName, attr.nodeValue)
    })
    element.parentNode.replaceChild(newElement, element);
  })
}

const createLinks = () => {
  const listOfLinks = document.querySelector("#table-of-content")
  let i = 0;
  document.querySelectorAll('h2').forEach((element) => {
    var text = element.textContent;
    var id = i; 
    element.setAttribute('id', id); 
    listOfLinks.insertAdjacentHTML("beforeend", `<li class="mb-3"><a href=#${i}>${element.innerText}</a></li>`)
    i += 1;
  })

  
}

export {createArticlesTableOfContent}