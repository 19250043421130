import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["close", "open", "closeConfirmation"]

  close(e) {
    if (e.currentTarget.id == "padel-modal") {
      this.closeTarget.classList.remove("close")
    } else {
      this.closeTarget.classList.add("close")
    }
  }
  open(e) {
    if (e.currentTarget.id == 'confirm') {
      this.openTarget.classList.remove("d-none")
    }
  }
  closeConfirmation(e) {
    if (e.currentTarget.id == 'annul') {
    this.openTarget.classList.add("d-none")
    }
  }
}
