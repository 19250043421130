import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets= ['level', 'max']

  connect() {
    this.selected()
  }

  #desactivate(index) {
    Array.from(this.maxTarget.options).slice(0, index).forEach((option) => {
      option.disabled = true;
    })
  }

  selected() {
    const select = this.levelTarget.selectedIndex
    this.maxTarget.selectedIndex = select
    this.#desactivate(select)
  }

  #reactivate() {
    Array.from(this.maxTarget.options).forEach((option) => {
      option.disabled = false;
    })
  }

  change(e) {
    this.#reactivate()
    this.selected()
  }
}
