import { Controller } from "stimulus"
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default class extends Controller {
  connect() {
    const mapElement = document.getElementById('map');
    const marker = JSON.parse(mapElement.dataset.marker);

    if (mapElement) {
      mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;

      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [marker.longitude, marker.latitude],
        zoom: 12
      });

      ////////////////// OPTIONS ON THE MAP ////////////////////
      const nav = new mapboxgl.NavigationControl()
      map.addControl(nav)

      new mapboxgl.Marker()
        .setLngLat([marker.longitude, marker.latitude])
        .addTo(map)
    }
  }
}
