import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["bell", 'modal', 'index', 'form']
    static values = { id: String, connect: Boolean }
    connect() {
        this.token = document.querySelector('meta[name="csrf-token"]').content
        this.back = this.indexTarget.outerHTML
    }

    openModal() {
        if (this.connectValue) {
            this.modalTarget.classList.add('d-block')
        }
        else {
            window.location.href = '/users/connexion'
        }
    }
    
    closeModal(e) {
        if (e.target == this.modalTarget) {
            this.modalTarget.classList.remove("d-block")
        }
    }

    backToList(e) {
        this.formTarget.outerHTML = this.back
    }

    setAlert(e) {
        e.preventDefault()
        fetch(e.target.action, {
            method: e.target.dataset.method,
            headers: {
                "Accept": "text/plain",
            },
            body: new FormData(e.target)
        })
            .then(res => res.text())
            .then(data => {
                this.formTarget.outerHTML = data
            })
    }

    closeFromButton() {
        this.modalTarget.classList.remove("d-block")
    }

    getForm(e) {
        e.preventDefault()
        fetch('/alerts/new', { headers: { "Accept": "text/plain" } })
            .then(res => res.text())
            .then(data => {
                this.indexTarget.outerHTML = data
            })
    }

    updateFormAlert(e) {
        e.preventDefault()
        const id = e.params['id']
        fetch(`/alerts/${id}/edit`, {
            method: 'GET',
            headers: {
                "Accept": "text/plain",
                "Content-Type": "application/json"
            }
        })
            .then(res => res.text())
            .then(data => {
                this.indexTarget.outerHTML = data
            })
    }

    destroy(e) {
        console.dir(e)
        e.stopPropagation()
        fetch(`/alerts/${e.params['id']}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'text/plain',
                'X-CSRF-Token': this.token
            }
        })
            .then(response => response.text())
            .then(data => {
                this.formTarget.outerHTML = data
            })
    }
}
